<template>
  <div>
    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">
<!--              <div class="form-group col-md-6 mt-2 mb-2">-->
<!--                <label>{{ $t('profit_of_sales_item_report.customers') }}</label>-->
<!--                <multiselect v-model="customers_ids"-->
<!--                             :placeholder="$t('profit_of_sales_item_report.customers')"-->
<!--                             label="fullname"-->
<!--                             track-by="id"-->
<!--                             :options="customers"-->
<!--                             :multiple="true"-->
<!--                             @input="getIds('customers')"-->
<!--                             :taggable="false"-->
<!--                             :show-labels="false"-->
<!--                             :show-no-options="false"-->
<!--                             :show-no-results="false"-->
<!--                             @search-change="getCustomers($event)">-->
<!--                </multiselect>-->
<!--              </div>-->
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('profit_of_sales_item_report.user') }}</label>
                <multiselect v-model="user"
                             :placeholder="$t('profit_of_sales_item_report.user')"
                             label="name"
                             track-by="id"
                             :options="users"
                             :multiple="false"
                             @input="getIds('user')"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('customer_transactions_report.branches') }}</label>
                <multiselect v-model="branches_ids"
                             :placeholder="$t('customer_transactions_report.branches')"
                             label="name"
                             track-by="id"
                             :options="branches"
                             :multiple="true"
                             @input="getIds('branches')"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="inventory_id">{{ $t('inventory_transaction_report.inventory') }}</label>
                <multiselect v-model="inventories_ids"
                             :placeholder="$t('inventory_transaction_report.inventory')"
                             label="name"
                             track-by="id"
                             :options="inventories"
                             @input="getIds('inventories')"
                             :multiple="true"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="item_id">{{ $t('profit_of_sales_item_report.items') }}</label>
                <div class="d-flex search-item-group">
                  <select v-model="f_by" class="custom-select" name="" style="width: 100px;">
                    <option selected value="name">{{ $t('items.name') }}</option>
                    <option value="sku_code">{{ $t('items.sku_code') }}</option>
                  </select>
                  <multiselect id="item_id"
                               v-model="items_ids"
                               :internal-search="false"
                               :multiple="true"
                               :options="items"
                               :label="f_by?f_by:'sku_code'"
                               @input="getIds('items')"
                               :placeholder="$t('profit_of_sales_item_report.items')"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               :taggable="false"
                               track-by="id"
                               @search-change="getItems(f_by, $event)">
                  </multiselect>
                </div>
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">
                  {{ $t('inventory_transaction_report.from_date') }}
                </label>
                <input type="date" id="from_date" v-model="filters.from_date" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('inventory_transaction_report.to_date') }}</label>
                <input type="date" id="to_date" v-model="filters.to_date" class="form-control">
              </div>
              <div class="col-md-6 mt-2 mb-2 pt-10">
                <div>
                  <b-button-group>
                    <b-button  @click="changeIntervalType('current_day')" :variant="filters.period=='current_day'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_date')}}</b-button>
                    <b-button  @click="changeIntervalType('current_month')" :variant="filters.period=='current_month'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_month')}}</b-button>
                    <b-button  @click="changeIntervalType('current_year')" :variant="filters.period=='current_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_year')}}</b-button>
                    <b-button  @click="changeIntervalType('financial_year')" :variant="filters.period=='financial_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>
<!--                    <b-button  @click="changeFinancialYear" :variant="filters.financial_year==1? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>-->
                  </b-button-group>

                </div>
                <div class="mt-5">
                  <b-button-group>
                    <b-button  @click="changeIntervalType('yesterday')" :variant="filters.period=='yesterday'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.yesterday')}}</b-button>
                    <b-button  @click="changeIntervalType('last_month')" :variant="filters.period=='last_month'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.last_month')}}</b-button>
                    <b-button  @click="changeIntervalType('last_year')" :variant="filters.period=='last_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.last_year')}}</b-button>
                  </b-button-group>

                </div>
              </div>
              <div class="form-group d-inline-flex col-md-6 mt-10">
                <b-button class="mt-auto mr-2" variant="primary" @click="doFilter"> {{ $t('display_report') }}</b-button>
                <b-button class="mt-auto mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}</b-button>

              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>


    <div class="card card-custom">
      <div class="card-body">
        <div>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row">
              <div class="col-12 mt-10" id="summaryTableTitle">
                <h4 class="text-center">{{ $t('MENU.profit_of_sales_item_report') }}</h4>
                <h5 class="text-center"><span v-if="filters.from_date">{{ $t('from') }} {{ filters.from_date }}</span> <span v-if="filters.to_date">{{ $t('to') }} {{ filters.to_date }}</span></h5>
              </div>
              <div class="col-12 mt-10 d-flex justify-content-end">
                <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
<!--                  <download-excel-->
<!--                      class="dropdown-item"-->
<!--                      :fetch="dataReport"-->
<!--                      :fields="json_fields"-->
<!--                      :name="$t('MENU.profit_of_sales_item_report')+'.xls'">-->
<!--                    <i class="la la-file-excel"></i>{{ $t('excel') }}-->
<!--                  </download-excel>-->
                  <button class="dropdown-item" @click="convertTableToExcelReport('summaryTable')">
                    <span><i class="fa fa-file-excel"></i> <span>{{ $t('excel') }}</span></span>
                  </button>

                  <button class="dropdown-item" @click="printData('summaryTable')">
                    <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                  </button>
                  <button class="dropdown-item" @click="getAllDataForExport('sectionForExport', 'excel')">
                    <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                  </button>
                </b-dropdown>
              </div>
              <div class="col-12 mt-10">
                <div class="table-responsive">
                  <table class="table table-bordered text-center" id="summaryTable">
                    <thead>
                    <tr>
                      <th rowspan="2"><span style="transform: translateY(-113%);display: block;">#</span></th>
                      <th rowspan="2"><span style="transform: translateY(-113%);display: block;">{{ $t('profit_of_sales_item_report.item_name') }}</span></th>
                      <th colspan="2">{{ $t('profit_of_sales_item_report.sales') }}</th>
                      <th colspan="2">{{ $t('profit_of_sales_item_report.return') }}</th>
                      <th colspan="2">{{ $t('profit_of_sales_item_report.net_sales') }}</th>
                      <th rowspan="2"><span style="transform: translateY(-113%);display: block;">{{ $t('profit_of_sales_item_report.total_cost') }}</span></th>
                      <th colspan="2">{{ $t('profit_of_sales_item_report.profit') }}</th>
                      <th colspan="2">{{ $t('profit_of_sales_item_report.profit_rate') }}</th>

                    </tr>
                    <tr>
                      <th>{{ $t('profit_of_sales_item_report.quantity') }}</th>
                      <th>{{ $t('profit_of_sales_item_report.value') }}</th>
                      <th>{{ $t('profit_of_sales_item_report.quantity') }}</th>
                      <th>{{ $t('profit_of_sales_item_report.value') }}</th>
                      <th>{{ $t('profit_of_sales_item_report.quantity') }}</th>
                      <th>{{ $t('profit_of_sales_item_report.value') }}</th>
                      <th>{{ $t('profit_of_sales_item_report.value') }}</th>
                      <th>{{ $t('profit_of_sales_item_report.percentage') }}</th>
                      <th>{{ $t('profit_of_sales_item_report.to_sales') }}</th>
                      <th>{{ $t('profit_of_sales_item_report.to_cost') }}</th>

                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(row, index) in data">
                      <tr>
                        <td colspan="13" style="text-align: start !important;">{{ row.label }}</td>
                      </tr>
                      <tr v-for="(_row, _index) in row.items" :key="'date'+index+'row'+_index">
                        <td>{{ _index + 1}}</td>
                        <td>{{ _row.item_name }}</td>
                        <td>{{ _row.sales_item_qty }}</td>
                        <td>{{ _row.sale_item_amount }}</td>
                        <td>{{ _row.all_sales_returns_qty }}</td>
                        <td>{{ _row.all_sales_returns_amount  }}</td>
                        <td>{{ _row.net_sales_qty }}</td>
                        <td>{{ _row.net_sales_amount }}</td>
                        <td>{{ _row.total_cost }}</td>
                        <td>{{ _row.profit }}</td>
                        <td>{{ _row.profit_percentage_net_sales}}</td>
                        <td>{{ _row.profit_percentage_sales }}</td>
                        <td>{{ _row.profit_percentage_cost }}</td>
                      </tr>
                    </template>

                    </tbody>
                    <tfoot>
                      <tr v-if="totals">
                        <td colspan="2" style="text-align: start !important;">{{ $t('total') }}</td>
                        <td></td>
                        <td>{{ totals.sales_sub_totals_before_tax_summation }}</td>
                        <td></td>
                        <td></td>
                        <td>{{ totals.net_sales_qty_summation }}</td>
                        <td>{{ totals.net_sales_amount_summation }}</td>
                        <td>{{ totals.total_cost_summation }}</td>
                        <td>{{ totals.profits_summation }}</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="col-12 text-center" v-if="page">
                  <button class="btn btn-warning" @click="loadMore">
                    <p class="mb-0">
                      <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                      {{ $t('load_more') }}
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Filter -->

      </div>
    </div>
    <!--end::customer-->

    <div class="export-container">
      <div class="section-for-export">
        <table class="table table-bordered text-center" id="sectionForExport">
          <thead>
          <tr>
            <th rowspan="2"><span style="transform: translateY(-113%);display: block;">#</span></th>
            <th rowspan="2"><span style="transform: translateY(-113%);display: block;">{{ $t('profit_of_sales_item_report.item_name') }}</span></th>
            <th colspan="2">{{ $t('profit_of_sales_item_report.sales') }}</th>
            <th colspan="2">{{ $t('profit_of_sales_item_report.return') }}</th>
            <th colspan="2">{{ $t('profit_of_sales_item_report.net_sales') }}</th>
            <th rowspan="2"><span style="transform: translateY(-113%);display: block;">{{ $t('profit_of_sales_item_report.total_cost') }}</span></th>
            <th colspan="2">{{ $t('profit_of_sales_item_report.profit') }}</th>
            <th colspan="2">{{ $t('profit_of_sales_item_report.profit_rate') }}</th>

          </tr>
          <tr>
            <th>{{ $t('profit_of_sales_item_report.quantity') }}</th>
            <th>{{ $t('profit_of_sales_item_report.value') }}</th>
            <th>{{ $t('profit_of_sales_item_report.quantity') }}</th>
            <th>{{ $t('profit_of_sales_item_report.value') }}</th>
            <th>{{ $t('profit_of_sales_item_report.quantity') }}</th>
            <th>{{ $t('profit_of_sales_item_report.value') }}</th>
            <th>{{ $t('profit_of_sales_item_report.quantity') }}</th>
            <th>{{ $t('profit_of_sales_item_report.percentage') }}</th>
            <th>{{ $t('profit_of_sales_item_report.to_sales') }}</th>
            <th>{{ $t('profit_of_sales_item_report.to_cost') }}</th>

          </tr>
          </thead>
          <tbody>
          <template v-for="(row, index) in dataList">
            <tr>
              <td colspan="13" style="text-align: start !important;">{{ row.label }}</td>
            </tr>
            <tr v-for="(_row, _index) in row.items" :key="'dates'+index+'rows'+_index">
              <td>{{ _index + 1}}</td>
              <td>{{ _row.item_name }}</td>
              <td>{{ _row.sales_item_qty }}</td>
              <td>{{ _row.sale_item_amount }}</td>
              <td>{{ _row.all_sales_returns_qty }}</td>
              <td>{{ _row.all_sales_returns_amount  }}</td>
              <td>{{ _row.net_sales_qty }}</td>
              <td>{{ _row.net_sales_amount }}</td>
              <td>{{ _row.total_cost }}</td>
              <td>{{ _row.profit }}</td>
              <td>{{ _row.profit_percentage_net_sales}}</td>
              <td>{{ _row.profit_percentage_sales }}</td>
              <td>{{ _row.profit_percentage_cost }}</td>
            </tr>
          </template>

          </tbody>
          <tfoot>
          <tr v-if="totalsList">
            <td colspan="2" style="text-align: start !important;">{{ $t('total') }}</td>
            <td></td>
            <td>{{ totalsList.sales_sub_totals_before_tax_summation }}</td>
            <td></td>
            <td></td>
            <td>{{ totalsList.net_sales_qty_summation }}</td>
            <td>{{ totalsList.net_sales_amount_summation }}</td>
            <td>{{ totalsList.total_cost_summation }}</td>
            <td>{{ totalsList.profits_summation }}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          </tfoot>
        </table>

      </div>
    </div>
    <div class="selector-export" id="selectorExport"></div>

    <div class="export-container">
      <div class="section-for-export" id="sectionForExportHeader">
        <report-main-header :print-type="'ProfitOfSalesItemReport'"></report-main-header>
      </div>
    </div>

  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import TableToExcel from "@linways/table-to-excel";
import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";

export default {
  name: "index-profit-of-sales-item-report",
  components: {ReportMainHeader},
  data() {
    return {
      mainRoute: '/reports/sales_via_interval/sales-refund-items',
      mainRouteDependency: 'base/dependency',
      items: [],
      data: [],
      totals: [],
      dataList: [],
      totalsList: [],

      filters: {
        from_date: null,
        to_date: null,
        // inventory_id: null,
        f_items_ids: [],
        user_id: null,

        f_branches_ids: [],
        f_inventories_ids: [],
        f_customers_ids: [],
        period: null,
        range: null,
        f_time_period: 0,
        financial_year: 0,
      },
      items_ids: [],
      // inventory: null,

      branches_ids: [],
      inventories_ids: [],

      branches: [],

      inventories: [],
      customers: [],
      customers_ids: [],
      users: [],
      user: null,

      period_date: ['day','week','month','year'],
      period_numbers: {
        day: 31,
        week: 4,
        month: 12,
        year: 10,
      },
      period_counter: [],
      currency_default_id: null,
      branch_default: null,
      inner_period: null,

      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page: 0,
      f_by: 'name',

    }
  },
  watch: {
    // item: function (val) {
    //   if (val && val.id) {
    //     this.filters.item_id = val.id;
    //   } else {
    //     this.filters.item_id = null;
    //   }
    // },
    // inventory: function (val) {
    //   if (val && val.id) {
    //     this.filters.inventory_id = val.id;
    //   } else {
    //     this.filters.inventory_id = null;
    //   }
    // }
  },
  computed: {

    json_fields: function () {
      let fields = {};
      fields[this.$t('inventory_transaction_report.time')] = 'time';
      fields[this.$t('inventory_transaction_report.type')] = 'type';
      fields[this.$t('inventory_transaction_report.product_name')] = 'product_name';
      fields[this.$t('inventory_transaction_report.inward')] = 'inward';
      fields[this.$t('inventory_transaction_report.outward')] = 'outward';
      fields[this.$t('inventory_transaction_report.warehouse')] = 'inventory_name';
      fields[this.$t('inventory_transaction_report.stock_level_after')] = 'stock_level_after';

      return fields;
    },


  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.sales_reports"), route: '/reports/sales-reports-links'}, {title: this.$t("MENU.profit_of_sales_item_report")}]);
    this.getInventories();
    this.getBranches();
    this.getUsers();
  },
  methods: {
    ...cssStypeForPrintReport,
    loadMore() {
      this.page = this.page ? (this.page + 1) : 1;
      this.getData();
    },
    doFilter() {
      this.page = 1;
      this.getData();
    },
    resetFilter() {
      this.filters.from_date = null;
      this.filters.to_date = null;

      this.items_ids = [];

      this.filters.period = null;
      this.filters.range = null;
      this.filters.f_time_period = 0;
      this.filters.financial_year = 0;
      this.filters.f_branches_ids = [];
      this.filters.f_inventories_ids = [];
      this.filters.f_items_ids = [];

      this.branches_ids = []
      this.inventories_ids = [];


      this.inner_period = null;

      this.getData();

    },
    getIds(related_with){

      switch (related_with) {
        case 'branches':
          this.filters.f_branches_ids = this.branches_ids.map((row) => row.id);
          break;
        case 'inventories':
          this.filters.f_inventories_ids = this.inventories_ids.map((row) => row.id);
          break;
        case 'customers':
          this.filters.f_customers_ids = this.customers_ids.map((row) => row.id);
          break;
        case 'items':
          this.filters.f_items_ids = this.items_ids.map((row) => row.id);
          break;
        case 'user':
          this.filters.user_id = this.user.id ? this.user.id : null;
          break;
      }

    },
    changeFinancialYear(){
      if (this.filters.financial_year == 1)
        this.filters.financial_year = 0;
      else
        this.filters.financial_year = 1;
    },
    changeIntervalType(action_type, range = null){
      if (this.filters.period == action_type){
        this.filters.period = null;
        this.filters.f_time_period = 0;
      }else{
        this.filters.period = action_type;
        this.filters.f_time_period = 1;
      }

      // this.filters.range = range;
      // if (range == 1){
      //   this.inner_period = 'last_'+action_type;
      //   if (action_type == 'year')
      //     this.filters.range = this.$moment().subtract(1, 'year').year();
      // }else{
      //   this.inner_period = action_type;
      // }


      // this.period_counter = [];
      // if (this.filters.period){
      //   let _counter = this.period_numbers[this.filters.period];
      //   if (this.filters.period == 'year') {
      //     let _current_year = new Date().getFullYear();
      //     this.period_counter.push(parseInt(_current_year));
      //     for (let cc = 1; cc <= _counter; cc++){
      //       this.period_counter.push(parseInt(_current_year) - cc);
      //     }
      //   }else {
      //     for (let cc = 1; cc <= _counter; cc++){
      //       this.period_counter.push(cc);
      //     }
      //   }
      //
      // }else {
      //   this.period_counter = [];
      // }

    },

    dataReport() {
      this.setReportLog('excel', 'sales profit report');
      return this.data;
    },
    getData() {
      let _limit = (this.page? this.page: 1) * 15;
      ApiService.get(this.mainRoute, {params: {...this.filters, limit: _limit}}).then((response) => {
        this.data = [];
        let _keys = Object.keys(response.data.data.data).sort().reverse();
        _keys.forEach((index)=>{
          this.data.push({
            label: index,
            items: response.data.data.data[index]
          });
        });

        // this.data = response.data.data.data.map((row, index)=>{
        //   console.log(row, index)
        //   return {
        //     label: index,
        //     items: [...row]
        //   }
        // });

        this.totals = response.data.data.totals;
      }).then().catch((error) => {
        this.$errorAlert(error)
      });
    },
    getAllDataForExport(tableId, _export) {
      ApiService.get(this.mainRoute, {params: {...this.filters, is_export: 1, export_all: 1}}).then((response) => {
        this.dataList = [];
        let _keys = Object.keys(response.data.data.data).sort().reverse();
        _keys.forEach((index)=>{
          this.dataList.push({
            label: index,
            items: response.data.data.data[index]
          });
        });

        this.totalsList = response.data.data.totals;
        if (_export == 'pdf' || _export == 'print')
          this.printData(tableId, _export);
        if (_export == 'excel')
          this.convertTableToExcelReport(tableId);
      });
    },
    convertTableToExcelReport(tableId) {
      this.setReportLog('excel', 'sales profit report');
      setTimeout(() => {
        TableToExcel.convert(document.getElementById(tableId), {
          name: this.$t('MENU.profit_of_sales_item_report') + '.xlsx',
          sheet: {
            name: 'Sheet 1'
          }
        });
      }, 100)
    },
    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter}}).then((response) => {
          this.items = response.data.data;
        });
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },

    async getCustomers(filter) {
      if (filter && filter.length >= 3)
        await ApiService.get(this.mainRouteDependency + "/customers", {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
    },
    getUsers() {
        ApiService.get(this.mainRouteDependency + "/users").then((response) => {
          this.users = response.data.data;
        });
    },

    printData(tableId) {
      this.setReportLog('pdf', 'sales profit report');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },

    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }
  },
};
</script>
